import { Link, useNavigate, useSearchParams } from '@remix-run/react';
import { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFieldArray, useForm } from 'react-hook-form';
import { Waypoint } from 'react-waypoint';
import useSWR from 'swr';

import { ActionSheet } from '../components/ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { ModalWrapper } from '../components/ConfirmCancelModalContext/ModalWrapper';
import { useGameLikeWorkspace } from '../components/Game/GameCenter';
import { useOpenSimpleGamePackPicker } from '../components/Game/GameCenter/GamePackSelect';
import { useGameLikeListLoader } from '../components/Game/GameCenter/List';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { RefreshIcon } from '../components/icons/RefreshIcon';
import { Loading } from '../components/Loading';
import config from '../config';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';
import { DateUtils } from '../utils/date';

export async function clientLoader() {
  return null;
}

function PropagateUGCSettingsModal(props: {
  packId: string;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const [result, setResult] = useState<number | null>(null);
  const {
    call: propagate,
    state: { state },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.gamePack.propagateUGCSettings(props.packId);
    setResult(resp.data.numOfAffectedPacks);
  });

  return (
    <ModalWrapper containerClassName='w-120' borderStyle='gray'>
      <div className='w-full flex flex-col gap-4 items-center p-6'>
        <header className='text-xl font-bold'>Propagate UGC settings</header>
        <section className='text-sm'>
          This will propagate the UGC settings of this pack and its blocks to
          all user generated game packs that are "forked" from this game pack.
          <div className='mt-2'>Which includes,</div>
          <ul className=' list-inside list-disc'>
            <li>Pack level AI Template</li>
            <li>Pack level Short Direction</li>
            <li>Block level UGC Asset</li>
            <li>Block level AI Template</li>
          </ul>
          {result !== null && (
            <div className='text-tertiary mt-2'>
              The UGC settings have been propagated to {result} game packs.
            </div>
          )}
        </section>

        <footer className='flex items-center justify-center gap-4'>
          {result !== null ? (
            <button
              type='button'
              className='btn-secondary w-40 h-10'
              onClick={props.onCancel}
            >
              Okay
            </button>
          ) : (
            <>
              <button
                type='button'
                className='btn-secondary w-40 h-10'
                onClick={props.onCancel}
                disabled={state.isRunning}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn-primary w-40 h-10'
                disabled={state.isRunning}
                onClick={propagate}
              >
                {state.isRunning ? <Loading text='' /> : 'Propagate'}
              </button>
            </>
          )}
        </footer>
      </div>
    </ModalWrapper>
  );
}

type FormData = {
  packs: GamePack[];
};

function TemplateEntry(props: {
  pack: GamePack;
  index: number;
  onRemove: (index: number) => void;
  openPropagateModal: (packId: string) => void;
}) {
  const { pack, index, onRemove, openPropagateModal } = props;

  return (
    <tr key={pack.id} className={`text-sms`}>
      <td>{pack.id}</td>
      <td>
        <Link
          to={`/admin/gamepacks?packId=${pack.id}`}
          target='_blank'
          className='underline'
        >
          <span>{pack.name}</span>
        </Link>
      </td>
      <td>
        <ActionSheet
          actions={[
            {
              kind: 'button',
              key: 'sync',
              icon: <RefreshIcon />,
              text: 'Propagate UGC Settings',
              onClick: () => {
                openPropagateModal(pack.id);
              },
            },
            {
              kind: 'button',
              key: 'delete',
              icon: <DeleteIcon />,
              className: 'text-red-002',
              text: 'Delete',
              onClick: () => {
                onRemove(index);
              },
            },
          ]}
          btnSizingClassName='w-7.5 h-7.5'
          placement='bottom-end'
        />
      </td>
    </tr>
  );
}

function ManageTemplatesEditor({
  tagId = config.misc.ugcTemplatesTagId,
  ...props
}: {
  onCancel: () => void;
  onSave: () => void;
  tagId?: number;
}) {
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting, defaultValues },
  } = useForm<FormData>({
    defaultValues: async () => {
      const packs = await apiService.gamePack
        .getGamePacksByTagId(tagId, false, 100)
        .next();
      return { packs };
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => {
    const updatedPackIds = data.packs.map((p) => p.id);
    const removedPacks = (defaultValues?.packs ?? []).filter(
      (p): p is GamePack => !!p && !!p.id && !updatedPackIds.includes(p.id)
    );

    const promises = [];
    for (const pack of data.packs) {
      const tagIds = (pack.tags ?? []).map((t) => t.id);
      if (tagIds.includes(tagId)) continue;
      promises.push(
        apiService.gamePack.updateGamePackTags(pack.id, {
          tagIds: [tagId, ...tagIds],
        })
      );
    }
    for (const pack of removedPacks) {
      const tagIds = (pack.tags ?? []).map((t) => t.id);
      if (!tagIds.includes(tagId)) continue;
      promises.push(
        apiService.gamePack.updateGamePackTags(pack.id, {
          tagIds: tagIds.filter((t) => t !== tagId),
        })
      );
    }
    await Promise.all(promises);
    props.onSave();
  });

  const {
    fields: packs,
    append,
    remove,
  } = useFieldArray<FormData, 'packs', 'key'>({
    control: control,
    name: 'packs',
    keyName: 'key',
  });

  const openPicker = useOpenSimpleGamePackPicker();

  const onAddTemplate = () => {
    openPicker({
      placeholder: 'Select Customize Template',
      onSave: (pack) => {
        if (!pack) return;
        if (packs.some((p) => p.id === pack.id)) return;
        append(pack);
      },
    });
  };

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const openPropagateModal = (packId: string) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <PropagateUGCSettingsModal
          packId={packId}
          onCancel={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
        />
      ),
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <header>
        <div className='px-5 h-20 flex items-center justify-between border-b border-secondary'>
          <div className='text-white font-bold text-xl'>Manage Templates</div>
          <div className='flex items-center gap-5'>
            <button
              type='button'
              onClick={props.onCancel}
              disabled={isSubmitting}
              className='btn-secondary w-33 h-10'
            >
              {isDirty ? 'Cancel' : 'Close'}
            </button>
            <button
              type='button'
              onClick={onSubmit}
              disabled={!isDirty || isSubmitting}
              className='btn-primary w-33 h-10 flex items-center justify-center'
            >
              {isSubmitting ? (
                <Loading text='' imgClassName='w-5 h-5' />
              ) : (
                <>Save</>
              )}
            </button>
          </div>
        </div>
      </header>
      <div className='flex flex-col gap-8 p-5'>
        <div className='text-sm'>
          This is just a shortcut for templates management, we use tags/category
          under the hood. <br />
          Please use the{' '}
          <Link
            to={`/admin/gamepacks/tags/${tagId}`}
            className='text-primary underline'
          >
            category tool
          </Link>{' '}
          for advanced control such as reordering.
        </div>
        <button
          type='button'
          className='btn text-sm self-start'
          onClick={onAddTemplate}
        >
          <span className='text-primary underline'>+Add Template</span>
        </button>
        <DndProvider backend={HTML5Backend}>
          <table>
            <thead>
              <tr className='text-left'>
                <th className='w-5/12'>Pack Id</th>
                <th className='w-4/12'>Pack Name</th>
                <th className='w-2/12'></th>
              </tr>
            </thead>
            <tbody>
              {packs.map((pack, index) => (
                <TemplateEntry
                  key={pack.key}
                  pack={pack}
                  index={index}
                  onRemove={remove}
                  openPropagateModal={openPropagateModal}
                />
              ))}
            </tbody>
          </table>
        </DndProvider>
      </div>
    </form>
  );
}

function CustomPackInfo(props: { pack: GamePack }) {
  const { pack } = props;
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  const { data: organizer } = useSWR(`/organizers/${pack.uid}`, async () => {
    const resp = await apiService.organization.getOrganizerByUid(pack.uid);
    return resp.data.organizer;
  });

  const { data: files } = useSWR(
    `/game-packs/${pack.id}/ugc-files`,
    async () => {
      const resp = await apiService.gamePack.getUGCFiles(pack.id);
      return resp.data.files;
    }
  );

  const navigate = useNavigate();

  const viewFiles = () => {
    if (!files || files.length === 0) return;
    navigate(`./${pack.id}/files`);
  };

  return (
    <tr className='w-full h-10 text-sms hover:bg-lp-gray-002 odd:bg-lp-gray-001'>
      <td
        className='underline cursor-pointer'
        onClick={() => setActiveGamePack(pack)}
      >
        {pack.name}
      </td>
      <td>
        <Link className='underline' to={`/admin/users?uid=${pack.uid}`}>
          {organizer?.firstName}
        </Link>
      </td>
      <td>{organizer?.organization?.name}</td>
      <td>{DateUtils.FormatDatetime(pack.createdAt)}</td>
      <td
        className={`${
          files && files.length > 0 ? 'cursor-pointer underline' : ''
        }`}
        onClick={viewFiles}
      >
        {files?.length}
      </td>
    </tr>
  );
}

export function Component() {
  const [manageTemplates, setManageTemplates] = useState(false);
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const paginator = useMemo(
    () =>
      apiService.gamePack.searchGamePacks(q ?? '', {
        ugcOnly: 'true',
        timeSort: 'created_at',
      }),
    [q]
  );
  const { items, state, error, handleLoadMore } =
    useGameLikeListLoader(paginator);
  const canLoad = state.isStarted && !state.isRunning && !error;

  return (
    <div className='w-full relative text-white p-10'>
      <header className='flex justify-between items-center'>
        <h1 className='font-bold text-3xl'>Custom Game Packs</h1>
        <button
          type='button'
          className='w-50 h-10 btn-primary'
          onClick={() => setManageTemplates(true)}
        >
          Manage Templates
        </button>
      </header>
      <table className='w-full my-10'>
        <thead className='text-left h-12 text-base text-bold'>
          <tr>
            <th>Pack Name</th>
            <th>Creator</th>
            <th>Organization</th>
            <th>Created At</th>\<th>UGC Files</th>
          </tr>
        </thead>
        <tbody>
          {items.map((pack) => (
            <CustomPackInfo key={pack.id} pack={pack} />
          ))}
        </tbody>
      </table>
      {state.isRunning && (
        <div className='w-full flex items-center justify-center text-white my-8'>
          <Loading />
        </div>
      )}
      {canLoad && (
        <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
          <div>&nbsp;</div>
        </Waypoint>
      )}

      {manageTemplates && (
        <div className='fixed inset-0 z-50'>
          <div className='absolute inset-0 bg-lp-black-001' />
          <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
            <ManageTemplatesEditor
              onCancel={() => setManageTemplates(false)}
              onSave={() => setManageTemplates(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
